var misc = {
	el: {
		doc: $(document),
		body: $('body'),
		login: $('.login'),
		footer: $('.footer'),
		video: $('.video'),
		videoPlayer: $('video'),
		videoModal: $('.video-modal'),
		videoClose: $('.video--close'),
		menuTrigger: $('.menu-trigger'),
		menuResponsive: $('.responsive-menu'),
		filter: $('.filter'),
		statusBtn: $('.status-btn'),
		statusPrizeModalContainer: $('.prize-status')
	},
	events: function () {
		var self = this;
		this.el.login.length ? this.el.footer.css('bottom', 0) : ''

		this.el.video.on('click', function () {
			$(this).next().css({
				'display': 'flex',
				'align-items': 'center'
			});
			self.el.body.css('overflow', 'hidden')
			self.el.videoClose.show();
		})

		this.el.videoModal.add(this.el.videoClose).on('click', function () {
			self.el.videoModal.add(self.el.videoClose).hide()
			self.el.body.removeAttr('style')
			self.el.videoPlayer[0].pause()
			self.el.videoPlayer[0].currentTime = 0;
		})


		this.el.menuTrigger.on('click', function () {
			$(this).find('li').toggleClass('change');
			self.el.menuResponsive.toggleClass('active')
		})

		this.el.filter.on('click', function (e) {
			e.preventDefault();
			$(this).toggleClass('up');
		});

		this.el.menuResponsive.find('ul').find('ul').find('a').on({
			mouseenter: function () {
				$(this).closest(self.el.menuResponsive).find('ul').first().find('li').first().find('a').css("color", "#ed1c24");
			},
			mouseleave: function () {
				$(this).closest(self.el.menuResponsive).find('ul').first().find('li').first().find('a').css("color", "#252525");
			}
		})

	},

	statusPrizeModal: function () {
		var self = this;

		this.el.statusBtn.find('.close').on('click', function (e) {
			e.preventDefault();
			self.el.statusPrizeModalContainer.hide()
		});

		this.el.doc.on('click', '.movements p, .tableResponsive p', function () {
			self.el.statusPrizeModalContainer.show();
			self.el.statusPrizeModalContainer.find('g').siblings().removeClass('active last');

			$(this).data('status') === 'asked'.toUpperCase() ? self.el.statusPrizeModalContainer.find('g').first().addClass('active') : ''
			$(this).data('status') === 'confirmed'.toUpperCase() ? self.el.statusPrizeModalContainer.find('g:nth-of-type(-n+2)').addClass('active') : ''
			$(this).data('status') === 'requested'.toUpperCase() ? self.el.statusPrizeModalContainer.find('g:nth-of-type(-n+3)').addClass('active') : ''
			$(this).data('status') === 'expedited'.toUpperCase() ? self.el.statusPrizeModalContainer.find('g:nth-of-type(-n+4)').addClass('active') : ''
			$(this).data('status') === 'delivered'.toUpperCase() ? self.el.statusPrizeModalContainer.find('g:nth-of-type(-n+5)').addClass('active') : ''

			self.el.statusPrizeModalContainer.find('g').first().addClass('first');
			$('.active').length - 1 ? $('.active').last().addClass('last').siblings().removeClass('first') : ''
		})
	},

	init: function () {
		this.events();
		this.statusPrizeModal();
	}
}
misc.init()