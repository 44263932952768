var getList = {

	el: {
		doc: $(document),
		aside: $('aside'),
		productList: $('.product-list'),
		showResults: $('.paginator'),
		dataFilter: $('[data-filter]')
	},

	events: function (e) {
		var self = this;

		$.ajax({
			url:  $(e).hasClass('js-favorite') ? self.el.aside.data('get-favorites-endpoint') : self.el.aside.data('products-endpoint'),
			type: $(e).hasClass('js-favorite') ? 'GET' : 'POST',
			dataType: 'json',
			contentType: 'application/json',
			data: $(e).hasClass('js-favorite') ? '' : JSON.stringify({
				queryConstraints: {
					f: typeof $(e).data('filter') !== 'undefined' ? $(e).data('filter') : '',
					q: $('input[name="search"]').val()
				},
				tree:   $(e).hasClass('js-favorite') ? '' : $(e).hasClass('submit') ? [self.el.aside.data('menu-endpoint').substring(self.el.aside.data('menu-endpoint').lastIndexOf('/') + 1)] : [$(e).data('id')]
			}),
			beforeSend: function () {
				self.el.productList.append('<div class="loading">A carregar</div>')
			},
		}).done(function (data) {
			self.el.doc.find('.product-detail').hide()
			$('.loading').remove();
			self.el.productList.find('.item').remove();
			self.el.showResults.find('span').first().text('');

			var response = data.data,
				pagination = data.paging

			$.each(response, function (i, v) {

				self.el.productList.append(
					'<li data-id="' + response[i].id + '" class="item">' +
					'<div class="item-image">' +
					'<img src="' + response[i].image + '" alt=""/>' +
					'</div>' +
					'<div class="item-contents">' +
					'<p class="item-name">' +
					response[i].name +
					'</p>' +
					'<p class="item-brand">' +
					response[i].brand +
					'</p>' +
					//'<p class="item-rate"> Mais pedido: ' +
					//response[i].requestRate +
					//'</p>' +
					'<p class="item-points">' +
					'<span>' +
					response[i].points +
					'</span>' +
					" Pontos" +
					'</p>' +
					'</div>' +
					'</li>'
				);
			})

			$.each(pagination, function () {
				$('.paginator span:first-child').text(pagination.total_Results === 1 ? 'Foi encontrado ' + pagination.total_Results + ' resultado' : 'Foram encontrados ' + pagination.total_Results + ' resultados')
			})


		}).fail(function (jqXHR) {
			console.error(jqXHR.statusText)
		})
	},
}