var accordeon = {
	el: {
		container: $('.js-accordeon')
	},

	events: function() {
		this.el.container.each(function(){
			$(this).find('.list__row--accordeon-open').css('display', 'block')
			$(this).children('.js-toggle-accordeon').on('click', function(){
				if($(this).next().hasClass('list__row--accordeon-open')) {
					$(this).next().slideUp().removeClass('list__row--accordeon-open')
					$(this).find('.list__toggle').removeClass('close')
				} else {
					$(this).next().slideToggle()
					$(this).find('.list__toggle').toggleClass('close')
				}
			})
		})
	},

	init: function() {
		this.events()
	}
}

accordeon.init()