var table = {
	el: {
		userPoints: $('.user-points'),
		userPrizes: $('.user-prizes'),
		program: $('.program')
	},

	vars: {
		smallAndUp: window.matchMedia('(min-width: 500px)'),
		mediumAndUp: window.matchMedia('(min-width: 768px)'),
		largeAndUp: window.matchMedia('(min-width: 1200px)')
	},


	createTable: function(elem) {
		var self = this;		
		$(elem).each(function () {
			var table = $(this); // cache table object
			var head = table.find('thead th');
			var rows = table.find('tbody tr').clone() // appending afterwards does not break original table
	
			// create new table
			var newtable = $(
				'<table class="tableResponsive">' +
				'  <tbody>' +
				'  </tbody>' +
				'</table>'
			);
	
			// cache tbody where we'll be adding data
			var newtable_tbody = newtable.find('tbody');
	
			rows.each(function (i) {
				var cols = $(this).find('td');
				var classname = i % 2 ? 'even' : 'odd';
	
				cols.each(function (k) {
					var new_tr = $('<tr class="' + classname + '"></tr>').appendTo(newtable_tbody);
					new_tr.append(head.clone().get(k));
					new_tr.append($(this));
				});
			});
	
			$(this).after(newtable);
		});
	
	},

	events: function() {		

		if(!this.vars.largeAndUp.matches) 
			this.createTable(this.el.userPrizes.find('.movements'))	
		
		if(!this.vars.mediumAndUp.matches)
			this.createTable(this.el.userPoints.find('.movements'))
		
		if(!this.vars.smallAndUp.matches)
			this.createTable(this.el.program.find('table:first-of-type'))
	},

	init: function () {
		this.events();
	}
}

table.init()

// if ($(window).width() < 500) {

//     $('.program .step .container .table-container table:first-child').each(function() {
//         var table = $(this); // cache table object
//         var head = table.find('thead th');
//         var rows = table.find('tbody tr').clone(); // appending afterwards does not break original table
    
//         // create new table
//         var newtable = $(
//         '<table class="tableResponsive">' +
//         '  <tbody>' +
//         '  </tbody>' +
//         '</table>'
//         );
    
//         // cache tbody where we'll be adding data
//         var newtable_tbody = newtable.find('tbody');
    
//         rows.each(function(i) {
//         var cols = $(this).find('td');
//         var classname = i % 2 ? 'even' : 'odd';
//         cols.each(function(k) {
//             var new_tr = $('<tr class="' + classname + '"></tr>').appendTo(newtable_tbody);
//             new_tr.append(head.clone().get(k));
//             new_tr.append($(this));
//         });
//         });
    
//         $(this).after(newtable);
//     });
// }

// if ($(window).width() < 768) {

//     $('.user-points .movements').each(function() {
//         var table = $(this); // cache table object
//         var head = table.find('thead th');
//         var rows = table.find('tbody tr').clone(); // appending afterwards does not break original table
    
//         // create new table
//         var newtable = $(
//         '<table class="tableResponsive">' +
//         '  <tbody>' +
//         '  </tbody>' +
//         '</table>'
//         );
    
//         // cache tbody where we'll be adding data
//         var newtable_tbody = newtable.find('tbody');
    
//         rows.each(function(i) {
//         var cols = $(this).find('td');
//         var classname = i % 2 ? 'even' : 'odd';
//         cols.each(function(k) {
//             var new_tr = $('<tr class="' + classname + '"></tr>').appendTo(newtable_tbody);
//             new_tr.append(head.clone().get(k));
//             new_tr.append($(this));
//         });
//         });
    
//         $(this).after(newtable);
//     });
// }

// if ($(window).width() < 1200) {

//     $('.user-prizes .movements').each(function() {
//         var table = $(this); // cache table object
//         var head = table.find('thead th');
//         var rows = table.find('tbody tr').clone(); // appending afterwards does not break original table
    
//         // create new table
//         var newtable = $(
//         '<table class="tableResponsive">' +
//         '  <tbody>' +
//         '  </tbody>' +
//         '</table>'
//         );
    
//         // cache tbody where we'll be adding data
//         var newtable_tbody = newtable.find('tbody');
    
//         rows.each(function(i) {
//         var cols = $(this).find('td');
//         var classname = i % 2 ? 'even' : 'odd';
//         cols.each(function(k) {
//             var new_tr = $('<tr class="' + classname + '"></tr>').appendTo(newtable_tbody);
//             new_tr.append(head.clone().get(k));
//             new_tr.append($(this));
//         });
//         });
    
//         $(this).after(newtable);
//     });
// }
