var favorites = {

	el: {
		doc: $(document),
		aside: $('aside'),
		breadcrumbs: $('.breadcrumbs'),
		productDetail: $('.product-detail')

	},

	events: function () {
		var self = this;

		this.el.doc.on('click', '.js-favorite', function () {
			self.el.breadcrumbs.find('li').text('')
			self.el.productDetail.find('.product-detail-header, .product-detail-container, .product-detail-other-images').remove()
			sidebar.showTable()
			getList.events(this)
		})

		this.el.doc.on('click', '.js-add-favorite', function (e) {
			$(this).toggleClass('favorite');
			e.target.firstChild.nodeValue === 'marcar favorito'.toUpperCase() ? e.target.firstChild.nodeValue = 'remover favorito'.toUpperCase() : e.target.firstChild.nodeValue = 'marcar favorito'.toUpperCase();

			$.ajax({
				url: self.el.aside.data('add-favorites-endpoint') + $(e.target).data('id'),
				type: 'POST',
				dataType: 'json',
				contentType: 'application/json',
				data: $(e.target).data('id')

			}).done(function (data) {
				console.log(data);

			}).fail(function (jqXHR) {
				console.error(jqXHR.responseText)
			})

		})

	},

	init: function () {
		this.events();
	}

}

favorites.init()