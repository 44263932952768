//FIX must be 100% Native JS to check older IE versions <9
const detectIE = {

	el: {
		body: document.querySelector('body'),
		msie: window.navigator.userAgent.indexOf('MSIE'), //IE6-10
		//trident: window.navigator.userAgent.indexOf('Trident/') IE11
	},

	events: function () {
		var oldBrowserBody = this.el.body;
		//FIX IE8 classname += '' instead add() for IE9 lower
		this.el.msie > 0  ? 
		(oldBrowserBody.className += 'old-browser', oldBrowserBody.insertAdjacentHTML("afterbegin",'<span class="old-browser__message">Navegador não suportado. Clique&nbsp;<a target="_blank" href="http://outdatedbrowser.com/pt">aqui</a>&nbsp;para actualizar</span>'), $.scrollify.disable()) : ''
	},
	init: function() {
		this.events();
	}

}

detectIE.init();